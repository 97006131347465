import Vue from 'vue'
import generateUid from '@/helpers/uid'

Vue.component('Autocomplete', {
  functional: true,
  render (createElement, context) {
    let { staticClass = '', attrs, props } = context.data
    const attrVal = attrs['hide-details']
    const hideDetails = typeof attrVal === 'undefined' || attrVal === 'auto' ? 'auto' : (attrVal === '' ? true : attrVal)
    const autoPadding = typeof attrs['no-padding'] === 'undefined' && attrs.label ? 'v-text-field--auto-padding' : null

    if (autoPadding) {
      staticClass = staticClass ? `${staticClass} ${autoPadding}` : autoPadding
    }

    if (attrs?.rules) {
      const vid = generateUid(attrs.name)
      return createElement('ValidationProvider', {
        props: {
          vid,
          name: attrs.name,
          rules: attrs.rules
        },
        scopedSlots: {
          default: function (slotProps) {
            return createElement('v-autocomplete', {
              ...context.data,
              staticClass,
              attrs: {
                ...attrs,
                rules: []
              },
              props: {
                ...props,
                hideDetails,
                outlined: true,
                filled: true,
                dense: true,
                clearIcon: 'highlight_off',
                errorMessages: slotProps.errors
              }
            }, context.children)
          }
        }
      })
    }
    return createElement('v-autocomplete', {
      ...context.data,
      staticClass,
      attrs: {
        ...attrs,
        rules: []
      },
      props: {
        ...props,
        hideDetails,
        outlined: true,
        filled: true,
        dense: true,
        clearIcon: 'highlight_off'
      }
    }, context.children)
  }
})
