/* eslint camelcase: ["error", {ignoreImports: true}] */
import { extend, localize } from 'vee-validate'
import {
  required, email, min, max, numeric, min_value, max_value, length, alpha, alpha_spaces, alpha_num, double, digits, ext, size
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import de from 'vee-validate/dist/locale/de.json'

import { form } from '@/locales/en.json'
import german from '@/locales/de.json'

extend('required', required)

extend('min', min)

extend('max', max)

extend('email', email)

extend('numeric', numeric)

extend('min_value', min_value)

extend('max_value', max_value)

extend('length', length)

extend('alpha', alpha)

extend('alpha_spaces', alpha_spaces)

extend('alpha_num', alpha_num)

extend('double', double)

extend('digits', digits)

extend('ext', ext)

extend('size', size)

// regex validation is added in plugins/i18n.js to work locale messages correctly
// since regex field names are not predefined in vee-validate

localize({
  en: {
    messages: {
      ...en.messages,
      ...form.messages
    },
    names: {
      ...form.labels
    },
    fields: {
      password: {
        min: '{_field_} is too short'
      },
      file: {
        size: '{_field_} size must be less than {size}KB'
      }
    }
  },
  de: {
    messages: {
      ...de.messages,
      ...german.form.messages
    },
    names: {
      ...german.form.labels
    },
    fields: {
      password: {
        min: '{_field_} ist zu kurz'
      },
      file: {
        size: '{_field_} size must be less than {size}KB'
      }
    }
  }
})
