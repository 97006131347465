import themeColors from '@/helpers/themes'
import { themes } from '@/helpers/constants'

const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches

export default {
  namespaced: true,
  state: {
    isDark
  },
  getters: {
    themeClass: ({ isDark }) => isDark ? 'theme--dark' : 'theme--light'
  },
  mutations: {
    update (state, value) {
      state.isDark = value
    }
  },
  actions: {
    update ({ commit, dispatch }, { theme, color }) {
      commit('update', theme)
      dispatch('updateMeta', color)
    },
    updateMeta ({ state }, color) {
      document.getElementsByTagName('meta')['theme-color'].setAttribute('content', color)
    },
    compareSettings ({ state, dispatch }, theme) {
      let { isDark } = state
      const currentTheme = isDark ? themes.dark : themes.light
      if (currentTheme !== theme) {
        isDark = theme === themes.dark
        const color = themeColors[isDark ? 'dark' : 'light'].primary.base
        dispatch('update', { theme: isDark, color })
      }
    }
  }
}
