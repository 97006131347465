/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { freelancersFilterTabs } from '@/helpers/constants'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const state = () => ({
  filter: {
    keyword: [],
    rate: [],
    experience: [],
    start_date: null,
    end_date: null,
    skills: [],
    clients: [],
    bookmarked: false,
    available: true,
    items: 36 // per page items
  },
  selectedTab: freelancersFilterTabs.bookmarked
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      const { keyword, rate, experience, start_date, end_date, skills, clients } = filter
      const result = keyword.length || rate.length || experience.length || (start_date && end_date) || skills.length || clients.length
      return !!result
    },
    filterTags ({ filter }) {
      const { keyword, rate, experience, start_date, end_date, skills, clients } = filter
      const tags = []

      keyword.forEach(value => {
        const tag = { filter: 'keyword', value }
        tags.push(tag)
      })

      if (rate.length) {
        const [from, to] = rate
        tags.push({ filter: 'rate', value: `${from}€ - ${to}€` })
      }

      if (experience.length) {
        tags.push({ filter: 'experience', value: experience })
      }

      skills.forEach(({ id, name: value }) => {
        tags.push({ filter: 'skills', value, id })
      })

      clients.forEach(({ id, name: value }) => {
        tags.push({ filter: 'clients', value, id })
      })

      if (start_date && end_date) {
        tags.push({
          filter: 'duration',
          value: `${moment(start_date).format('DD.MM.YY')} - ${moment(end_date).format('DD.MM.YY')}`
        })
      }

      return tags
    },
    finalFilter ({ filter, selectedTab }, getters) {
      const finalFilter = cloneDeep(filter)

      if (!getters.hasAnyFilterApplied && selectedTab === freelancersFilterTabs.bookmarked) {
        finalFilter.bookmarked = true
      }

      if (!finalFilter.start_date || !finalFilter.end_date) {
        delete finalFilter.start_date
        delete finalFilter.end_date
      } else {
        finalFilter.available = true
      }

      if (filter.rate.length) {
        finalFilter.rate = filter.rate.join(',')
      }

      if (filter.experience.length) {
        finalFilter.experience = filter.experience.join(',')
      }

      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    switchTab (state, tab) {
      state.selectedTab = tab
    },
    toggleAvailable (state, status) {
      state.filter.available = status
    },
    updateFilter (state, { rate, experience, start_date, end_date, skills, clients }) {
      state.filter.rate = [...rate]
      state.filter.experience = [...experience]
      state.filter.start_date = start_date
      state.filter.end_date = end_date
      state.filter.skills = skills
      state.filter.clients = clients
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value, id }) {
      if (filter === 'rate') {
        state.filter.rate = []
      } else if (filter === 'experience') {
        state.filter.experience = []
      } else if (state.filter[filter]) {
        const index = state.filter[filter].findIndex(item => {
          return ['skills', 'clients'].includes(filter) ? id === item.id : value === item
        })
        index !== -1 && state.filter[filter].splice(index, 1)
      } else if (filter === 'duration') {
        state.filter.end_date = null
        state.filter.start_date = null
      }
    }
  },
  actions: {
    switchTab ({ commit, dispatch }, tab) {
      commit('switchTab', tab)
      dispatch('freelancers/reloadFreelancers', null, { root: true })
    },
    toggleAvailable ({ commit, dispatch }, status) {
      commit('toggleAvailable', status)
      dispatch('freelancers/reloadFreelancers', null, { root: true })
    },
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('freelancers/reloadFreelancers', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('freelancers/reloadFreelancers', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('freelancers/reloadFreelancers', null, { root: true })
    }
  }
}
