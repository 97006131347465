import axios from 'axios'
const state = () => ({
  notifications: [],
  loading: false,
  currentPage: 1,
  isFinished: false,
  todoCount: 0,
  infoCount: 0,
  messageCount: 0,
  type: 'to_do'
})

export default {
  namespaced: true,
  state,
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    setNotifications (state, data) {
      state.isFinished = state.currentPage >= data.total_pages
      if (state.currentPage === 1) {
        state.notifications = data.data
      } else {
        state.notifications = [...state.notifications, ...data.data]
      }
      const unreadCount = state.notifications.reduce((prevCount, currentItem) => {
        return prevCount + currentItem.is_read ? 0 : 1
      }, 0)
      if (state.type === 'to_do' && unreadCount > state.todoCount) {
        state.todoCount = unreadCount
      } else if (state.type === 'info' && unreadCount > state.infoCount) {
        state.infoCount = unreadCount
      }
    },
    resetNotifications (state) {
      state.currentPage = 1
      state.isFinished = false
      state.notifications = []
    },
    clearAllNotifications (state) {
      state.currentPage = 1
      state.isFinished = true
      state.notifications = []
      if (state.type === 'to_do') {
        state.todoCount = 0
      } else {
        state.infoCount = 0
      }
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    incrementPage (state) {
      state.currentPage++
    },
    decrementCount (state) {
      if (state.type === 'to_do') {
        state.todoCount--
      } else {
        state.infoCount--
      }
    },
    removeNotification (state, id) {
      const index = state.notifications.findIndex(item => item.notification_id === id)
      if (index !== -1) {
        state.notifications.splice(index, 1)
      }
    },
    readNotification (state, id) {
      const index = state.notifications.findIndex(item => item.notification_id === id)
      if (index !== -1) {
        state.notifications[index].is_read = 1
      }
    },
    updateNotificationCount (state, { todoCount, infoCount, messageCount }) {
      state.todoCount = todoCount
      state.infoCount = infoCount
      state.messageCount = messageCount
    },
    setNotificationType (state, value) {
      state.type = value
    },
    updateNotificationTypeCount (state, value) {
      if (state.type === 'to_do') {
        state.todoCount = value
      } else {
        state.infoCount = value
      }
    }
  },
  getters: {},
  actions: {
    loadNotifications ({ state, commit }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('items', 100)
        params.append('page', state.currentPage)
        params.append('category', state.type)

        commit('toggleLoading', true)

        axios.get(`${process.env.VUE_APP_ACTIVITYLOG_URL}notifications?${params.toString()}`)
          .then(({ data }) => {
            if (data?.status === 'success') {
              data.data = data.data.map(item => ({ ...item, is_deleted: false }))
              commit('setNotifications', data)
              commit('updateNotificationTypeCount', data.unread_count)
              resolve()
            } else {
              reject(new Error('Error loading notifications'))
            }
            commit('toggleLoading', false)
          })
          .catch(() => {
            commit('toggleLoading', false)
            reject(new Error('Error loading notifications'))
          })
      })
    },
    loadNotificationsPage ({ commit, dispatch }) {
      commit('incrementPage')
      dispatch('loadNotifications')
    },
    reloadNotifications ({ commit, dispatch }) {
      commit('resetNotifications')
      dispatch('loadNotifications')
    },
    deleteNotification ({ state, commit, dispatch }, { id, isRead }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_ACTIVITYLOG_URL}notification/${id}`)
          .then(() => {
            commit('removeNotification', id)
            if (!isRead) {
              commit('decrementCount')
            }
            if (!state.isFinished) {
              dispatch('loadNotifications')
            }
            resolve()
          })
          .catch(() => {
            reject(new Error('Error deleting notification'))
          })
      })
    },
    deleteAllNotifications ({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_ACTIVITYLOG_URL}notifications?category=${state.type}`)
          .then(() => {
            commit('clearAllNotifications')
            resolve()
          })
          .catch(() => {
            reject(new Error('Error deleting all notifications'))
          })
      })
    },
    readNotification ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_ACTIVITYLOG_URL}notifications/read/${id}`)
          .then(() => {
            commit('readNotification', id)
            commit('decrementCount')
            resolve()
          })
          .catch(() => {
            reject(new Error('Error updating the notification read status'))
          })
      })
    }
  }
}
