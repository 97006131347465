<template>
  <v-app :dark="isDark">
    <v-main class="px-3 h-100">
      <router-view />
    </v-main>
    <ErrorNotifications />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ErrorNotifications from '@/components/shared/ErrorNotifications'

export default {
  name: 'App',
  components: {
    ErrorNotifications
  },
  computed: {
    ...mapState('theme', ['isDark'])
  },
  methods: {
    ...mapActions('auth', ['validateTokenExpiry'])
  },
  watch: {
    '$route.path' () {
      this.validateTokenExpiry()
    },
    isDark: {
      immediate: true,
      handler (value) {
        if (value !== this.$vuetify.theme.dark) {
          this.$vuetify.theme.dark = value
        }
      }
    }
  }
}
</script>
