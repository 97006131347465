<template>
  <div class="date-picker">
    <v-menu
      ref="menu"
      v-model="open"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :left="alignLeft && smAndUp"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <TextField
          :value="formatted"
          :label="label"
          :name="name"
          readonly
          :disabled="disabled"
          :no-padding="noPadding"
          prepend-inner-icon="calendar_today"
          :rules="rules"
          v-bind="attrs"
          v-on="on"
          @click:prepend-inner="open = true"
        />
      </template>

      <v-date-picker
        v-model="model"
        :min="min"
        :max="max"
        :active-picker.sync="activePicker"
        :first-day-of-week="weekdayStart"
        no-title
        @input="open = false"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
import { weekdayStart } from '@/helpers/constants'
import Breakpoints from '@/mixins/Breakpoints'

export default {
  name: 'DatePicker',
  mixins: [Breakpoints],
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'date'
    },
    rules: {
      type: String,
      default: ''
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    initialPicker: {
      type: String,
      default: 'DATE'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    alignLeft: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      weekdayStart,
      open: false,
      activePicker: this.initialPicker
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    formatted () {
      return this.model ? moment(this.model).format('DD.MM.YYYY') : null
    }
  },
  watch: {
    open (value) {
      if (value) {
        this.$nextTick(() => (this.activePicker = this.initialPicker))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-text-field {
    .v-input__slot {
      padding-right: 0;
    }
    input {
      height: 100%;
      max-height: unset;
    }
  }
</style>
