import cloneDeep from 'lodash/cloneDeep'
import { projectsFilterTabs, projectStatuses } from '@/helpers/constants'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const projectStatusKeys = {}
Object.keys(projectStatuses).forEach(key => (projectStatusKeys[projectStatuses[key]] = key))

const state = () => ({
  filter: {
    keyword: [],
    status: [],
    year: [],
    agencies: [],
    bookmarked: false,
    my_projects: true
  },
  selectedTab: projectsFilterTabs.bookmarked
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      const { keyword, year, status, agencies } = filter
      const result = keyword.length || year.length || status.length || agencies.length
      return !!result
    },
    filterTags ({ filter: items }) {
      const tags = []
      const keys = ['keyword', 'status', 'year', 'agencies']
      keys.forEach(filter => {
        items[filter].forEach(value => {
          const tag = { filter, value }
          if (filter === 'status') {
            tag.key = projectStatusKeys[value]
          }
          tags.push(tag)
        })
      })
      return tags
    },
    finalFilter ({ filter, selectedTab }, getters, rootState, rootGetters) {
      const finalFilter = cloneDeep(filter)

      if (!getters.hasAnyFilterApplied) {
        const { ongoing, paused, done } = projectStatuses

        switch (selectedTab) {
        case projectsFilterTabs.bookmarked:
          finalFilter.bookmarked = true
          break
        case projectsFilterTabs.ongoing:
          finalFilter.status = [ongoing]
          break
        case projectsFilterTabs.paused:
          finalFilter.status = [paused]
          break
        case projectsFilterTabs.done:
          finalFilter.status = [done]
          break
        }
      }

      const isFreelancer = rootGetters['auth/isFreelancer']
      const isAgency = rootGetters['auth/isAgency']

      if (!isFreelancer && !isAgency) {
        finalFilter.my_projects = false
        if (finalFilter.agencies.length) {
          finalFilter.agency_id = finalFilter.agencies[0].id
        }
      }

      delete finalFilter.agencies

      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    switchTab (state, tab) {
      state.selectedTab = tab
    },
    toggleMyProjects (state, status) {
      state.filter.my_projects = status
    },
    updateFilter (state, { year, status, agencies }) {
      state.filter.year = [...year]
      state.filter.status = [...status]
      state.filter.agencies = [...agencies]
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value }) {
      const index = state.filter[filter].findIndex(item => {
        return filter === 'agencies' ? value.id === item.id : value === item
      })
      index !== -1 && state.filter[filter].splice(index, 1)
    }
  },
  actions: {
    switchTab ({ commit, dispatch }, tab) {
      commit('switchTab', tab)
      dispatch('projects/reloadProjects', null, { root: true })
    },
    toggleMyProjects ({ commit, dispatch }, status) {
      commit('toggleMyProjects', status)
      dispatch('projects/reloadProjects', null, { root: true })
    },
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('projects/reloadProjects', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('projects/reloadProjects', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('projects/reloadProjects', null, { root: true })
    }
  }
}
