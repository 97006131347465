import compact from 'lodash/compact'

const getShortName = (piece1, piece2) => {
  if (!piece1 && !piece2) {
    return ''
  }
  const piece1Arr = compact(piece1.split(' '))
  if (!piece2) {
    if (piece1Arr.length > 1) {
      return (`${piece1Arr[0].substring(0, 1)}${piece1Arr[1].substring(0, 1)}`).toUpperCase()
    }
    return piece1.substring(0, 2).toUpperCase()
  }
  return (`${piece1.substring(0, 1)}${piece2.substring(0, 1)}`).toUpperCase()
}

export default getShortName
