import Vue from 'vue'
import generateUid from '@/helpers/uid'

Vue.component('SelectField', {
  functional: true,
  render (createElement, context) {
    let { staticClass = '', attrs, props } = context.data
    const attrVal = attrs['hide-details']
    const hideDetails = typeof attrVal === 'undefined' || attrVal === 'auto' ? 'auto' : (attrVal === '' ? true : attrVal)

    const classes = []
    if (typeof attrs['no-padding'] === 'undefined' && attrs.label) {
      classes.push('v-text-field--auto-padding')
    }

    if (typeof attrs.small !== 'undefined') {
      classes.push('v-select--small')
    }

    if (classes.length) {
      const classNames = classes.join(' ')
      staticClass = staticClass ? `${staticClass} ${classNames}` : classNames
    }

    if (attrs?.rules) {
      const vid = generateUid(attrs.name)
      return createElement('ValidationProvider', {
        props: {
          vid,
          name: attrs.name,
          rules: attrs.rules
        },
        scopedSlots: {
          default: function (slotProps) {
            return createElement('v-select', {
              ...context.data,
              staticClass,
              attrs: {
                ...attrs,
                rules: []
              },
              props: {
                ...props,
                hideDetails,
                outlined: true,
                filled: true,
                dense: true,
                errorMessages: slotProps.errors
              }
            }, context.children)
          }
        }
      })
    }
    return createElement('v-select', {
      ...context.data,
      staticClass,
      attrs: {
        ...attrs,
        rules: []
      },
      props: {
        ...props,
        hideDetails,
        outlined: true,
        filled: true,
        dense: true
      }
    }, context.children)
  }
})
