import Vue from 'vue'
import generateUid from '@/helpers/uid'

Vue.component('TextField', {
  functional: true,
  render (createElement, context) {
    let { staticClass = '', attrs, props } = context.data
    const attrVal = attrs['hide-details']
    const hideDetails = typeof attrVal === 'undefined' || attrVal === 'auto' ? 'auto' : (attrVal === '' ? true : attrVal)
    const classes = staticClass ? [staticClass] : []

    if ((typeof attrs['no-padding'] === 'undefined' || attrs['no-padding'] === false) && attrs.label) {
      classes.push('v-text-field--auto-padding')
    }

    if (typeof attrs['align-right'] !== 'undefined') {
      classes.push('v-text-field--align-right')
    }

    if (classes.length) {
      staticClass = classes.join(' ')
    }

    if (attrs?.rules) {
      const vid = generateUid(attrs.name)
      return createElement('ValidationProvider', {
        props: {
          vid,
          name: attrs.name,
          rules: attrs.rules
        },
        scopedSlots: {
          default: function ({ errors }) {
            const errorMessages = errors.length ? errors : (attrs['error-messages'] || [])
            return createElement('v-text-field', {
              ...context.data,
              staticClass,
              attrs: {
                ...attrs,
                rules: [],
                autocomplete: 'off'
              },
              props: {
                ...props,
                errorMessages,
                hideDetails,
                outlined: true,
                filled: true,
                dense: true,
                clearIcon: 'highlight_off'
              }
            }, context.children)
          }
        }
      })
    }
    return createElement('v-text-field', {
      ...context.data,
      staticClass,
      attrs: {
        ...attrs,
        rules: [],
        autocomplete: 'off'
      },
      props: {
        ...props,
        errorMessages: attrs['error-messages'] || [],
        hideDetails,
        outlined: true,
        filled: true,
        dense: true,
        clearIcon: 'highlight_off'
      }
    }, context.children)
  }
})
