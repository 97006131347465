/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep'
import { creditNotesFilterTabs } from '@/helpers/constants'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const state = () => ({
  filter: {
    keyword: [],
    start_date: null,
    end_date: null,
    agencies: [],
    freelancers: []
  },
  selectedTab: creditNotesFilterTabs.reviewed
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      const { keyword, start_date, end_date, agencies, freelancers } = filter
      const result = keyword.length || (start_date && end_date) || agencies.length || freelancers.length
      return !!result
    },
    filterTags ({ filter }) {
      const { keyword, start_date, end_date, agencies, freelancers } = filter
      const tags = []
      if (agencies.length) {
        agencies.forEach(value => {
          const tag = { filter: 'agencies', value: `${value.name}`, id: value.id }
          tags.push(tag)
        })
      }
      if (freelancers.length) {
        freelancers.forEach(value => {
          const tag = { filter: 'freelancers', value: `${value.first_name} ${value.last_name}`, id: value.id }
          tags.push(tag)
        })
      }

      keyword.forEach(value => {
        const tag = { filter: 'keyword', value }
        tags.push(tag)
      })

      if (start_date && end_date) {
        const startSplit = start_date.split('-')
        const endSplit = end_date.split('-')
        const start = `${startSplit[2]}.${startSplit[1]}.${startSplit[0].substring(2, 4)}`
        const end = `${endSplit[2]}.${endSplit[1]}.${endSplit[0].substring(2, 4)}`
        tags.push({
          filter: 'duration',
          value: `${start} - ${end}`
        })
      }
      return tags
    },
    finalFilter ({ filter, selectedTab }, getters) {
      const finalFilter = cloneDeep(filter)
      if (!getters.hasAnyFilterApplied) {
        finalFilter.status = selectedTab
      }
      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    switchTab (state, tab) {
      state.selectedTab = tab
    },
    updateFilter (state, { start_date, end_date, agencies, freelancers }) {
      state.filter.start_date = start_date
      state.filter.end_date = end_date
      state.filter.agencies = agencies
      state.filter.freelancers = freelancers
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value, id }) {
      if (state.filter[filter]) {
        if (filter === 'freelancers' || filter === 'agencies') {
          const index = state.filter[filter].findIndex(item => id === item.id)
          index !== -1 && state.filter[filter].splice(index, 1)
        } else {
          const index = state.filter[filter].findIndex(item => value === item)
          index !== -1 && state.filter[filter].splice(index, 1)
        }
      } else if (filter === 'duration') {
        state.filter.end_date = null
        state.filter.start_date = null
      }
    }
  },
  actions: {
    switchTab ({ commit, dispatch }, tab) {
      commit('switchTab', tab)
      dispatch('creditNote/reloadCreditNotes', null, { root: true })
    },
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('creditNote/reloadCreditNotes', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('creditNote/reloadCreditNotes', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('creditNote/reloadCreditNotes', null, { root: true })
    }
  }
}
