import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import store from '@/store'
import { VTextField, VTextarea, VSelect, VAutocomplete, VProgressCircular, VMenu, VDatePicker } from 'vuetify/lib'
import themes from '@/helpers/themes'
import '@/scss/override.scss'

Vue.use(Vuetify, {
  components: {
    VTextField,
    VTextarea,
    VSelect,
    VAutocomplete,
    VProgressCircular,
    VMenu,
    VDatePicker
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes,
    dark: !!store.state.theme?.isDark
  }
})
