import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { bookingsFilterTabs, projectBookingStatuses } from '@/helpers/constants'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const state = () => ({
  filter: {
    keyword: [],
    start_date: null,
    end_date: null,
    agencies: [],
    freelancers: [],
    status: []
  },
  selectedTab: bookingsFilterTabs.open
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      const { keyword, start_date: start, end_date: end, status, agencies, freelancers } = filter
      const result = keyword.length || status.length || agencies.length || freelancers.length || (start && end)
      return !!result
    },
    filterTags ({ filter: items }) {
      const tags = []
      const keys = ['keyword', 'status', 'agencies', 'freelancers']
      keys.forEach(filter => {
        items[filter].forEach(value => {
          if (filter === 'agencies') {
            tags.push({ filter, value: value.name, id: value.id })
          } else if (filter === 'freelancers') {
            tags.push({ filter, value: `${value.first_name} ${value.last_name}`, id: value.id })
          } else {
            const tag = { filter, value }
            tags.push(tag)
          }
        })
      })
      const { start_date: start, end_date: end } = items
      if (start && end) {
        tags.push({
          filter: 'duration',
          value: `${moment(start).format('DD.MM.YY')} - ${moment(end).format('DD.MM.YY')}`
        })
      }
      return tags
    },
    finalFilter ({ filter, selectedTab }, getters, rootState, rootGetters) {
      const finalFilter = cloneDeep(filter)

      if (!getters.hasAnyFilterApplied) {
        const { pending, approved, approverDeclined, freelancerDeclined, booked, finished, cancelled } = projectBookingStatuses

        switch (selectedTab) {
        case bookingsFilterTabs.open:
          finalFilter.status = [pending, approved, approverDeclined, freelancerDeclined, booked]
          break
        case bookingsFilterTabs.closed:
          finalFilter.status = [finished, cancelled]
          break
        }

        const date = new Date()
        const month = date.getMonth()
        const year = date.getFullYear()

        finalFilter.start_date = `${year}-${month < 6 ? '01' : '07'}-01`
        finalFilter.end_date = `${year}-${month < 6 ? '06' : '12'}-${month < 6 ? '30' : '31'}`
      }

      const isFreelancer = rootGetters['auth/isFreelancer']
      const isAgency = rootGetters['auth/isAgency']

      if (isFreelancer || isAgency) {
        finalFilter.agencies = []

        if (isFreelancer) {
          finalFilter.freelancers = []
        }
      }

      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    switchTab (state, tab) {
      state.selectedTab = tab
    },
    updateFilter (state, { status, agencies, freelancers, start_date: start, end_date: end }) {
      state.filter.status = [...status]
      state.filter.agencies = [...agencies]
      state.filter.freelancers = [...freelancers]
      state.filter.start_date = start
      state.filter.end_date = end
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value, id }) {
      if (state.filter[filter]) {
        const index = state.filter[filter].findIndex(item => {
          return ['agencies', 'freelancers'].includes(filter) ? id === item.id : value === item
        })
        index !== -1 && state.filter[filter].splice(index, 1)
      } else if (filter === 'duration') {
        state.filter.end_date = null
        state.filter.start_date = null
      }
    }
  },
  actions: {
    switchTab ({ commit, dispatch }, tab) {
      commit('switchTab', tab)
      dispatch('bookings/reloadBookings', null, { root: true })
    },
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('bookings/reloadBookings', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('bookings/reloadBookings', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('bookings/reloadBookings', null, { root: true })
    }
  }
}
