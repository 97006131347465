import axios from 'axios'
import { itemsPerPage } from '@/helpers/constants'
const state = () => ({
  perPageItems: itemsPerPage,
  currentPage: 1,
  loading: false,
  sorting: {
    by: 'created_at',
    order: 'DESC'
  },
  items: [],
  total: null
})

export default {
  namespaced: true,
  state,
  getters: {

  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setItems (state, data) {
      state.items = data
    },
    updatePerPageItems (state, val) {
      state.perPageItems = val
    },
    setPage (state, data) {
      state.currentPage = data
    },
    updateSort (state, data) {
      state.sorting = data
    },
    resetItems (state) {
      state.currentPage = 1
      state.items = []
    },
    setTotal (state, data) {
      state.total = data
    }
  },
  actions: {
    loadItems ({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('items', state.perPageItems)
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)
        params.append('page', state.currentPage)

        const filters = rootGetters['flatRateReviewFilter/finalFilter']
        Object.keys(filters).forEach(key => {
          const val = filters[key]
          if (Array.isArray(val)) {
            if (['freelancers', 'agencies', 'bookers'].includes(key)) {
              val.forEach((item) => {
                params.append(`${key}[]`, item.id)
              })
            } else {
              val.forEach((item) => {
                params.append(`${key}[]`, item)
              })
            }
          } else if (val) {
            params.append(key, val)
          }
        })
        commit('toggleLoading', true)
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}flatrate-review-list?${params.toString()}`)
          .then(({ data }) => {
            commit('toggleLoading', false)
            if (data.status === 'success') {
              const items = data.data.data
              commit('setItems', items)
              commit('setTotal', data.data.total)
              resolve(items)
            } else {
              reject(new Error('Error loading flatrate payments review list'))
            }
          }).catch(() => {
            commit('toggleLoading', false)
            reject(new Error('Error loading flatrate payments review list'))
          })
      })
    },
    async sortItems ({ commit, dispatch }, sort) {
      await commit('updateSort', sort)
      await dispatch('loadItems')
    },
    async updatePage ({ commit, dispatch }, option) {
      await commit('setPage', option.page)
      await commit('updatePerPageItems', option.itemsPerPage)
      await dispatch('loadItems')
    },
    reloadItems ({ commit, dispatch }) {
      commit('resetItems')
      dispatch('loadItems')
    }
  }
}
