export default {
  computed: {
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    mdAndUp () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    smAndUp () {
      return this.$vuetify.breakpoint.smAndUp
    }
  }
}
