/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep'
import { flatRatePaymentFilterTabs, flatRatePaymentStatuses } from '@/helpers/constants'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const flatRateStatusKeys = {}
Object.keys(flatRatePaymentStatuses).forEach(key => (flatRateStatusKeys[flatRatePaymentStatuses[key]] = key))

const state = () => ({
  filter: {
    keyword: [],
    start_date: null,
    end_date: null,
    submitted_start_date: null,
    submitted_end_date: null,
    freelancers: [],
    projects: [],
    agencies: [],
    status: []
  },
  selectedTab: flatRatePaymentFilterTabs.all
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }, getters, rootState, rootGetters) {
      const { keyword, start_date, end_date, submitted_start_date, submitted_end_date, freelancers, projects, agencies, status } = filter
      const isFreelancer = rootGetters['auth/isFreelancer']
      let result
      if (isFreelancer) {
        result = keyword.length || (start_date && end_date) || (submitted_start_date && submitted_end_date) || projects.length || status.length
      } else {
        result = keyword.length || (start_date && end_date) || (submitted_start_date && submitted_end_date) || freelancers.length || projects.length || agencies.length || status.length
      }
      return !!result
    },
    filterTags ({ filter }, getters, rootState, rootGetters) {
      const { keyword, start_date, end_date, submitted_start_date, submitted_end_date, freelancers, projects, agencies, status } = filter
      const tags = []
      if (freelancers.length) {
        freelancers.forEach(value => {
          const isFreelancer = rootGetters['auth/isFreelancer']
          if (!isFreelancer) {
            const tag = { filter: 'freelancers', value: `${value.first_name} ${value.last_name}`, id: value.id }
            tags.push(tag)
          }
        })
      }
      if (projects.length) {
        projects.forEach(value => {
          const tag = { filter: 'projects', value: value.name, id: value.id }
          tags.push(tag)
        })
      }
      if (agencies.length) {
        agencies.forEach(value => {
          const tag = { filter: 'agencies', value: value.name, id: value.id }
          tags.push(tag)
        })
      }
      if (status.length) {
        status.forEach(value => {
          const tag = { filter: 'status', value: flatRateStatusKeys[value] }
          tags.push(tag)
        })
      }
      keyword.forEach(value => {
        const tag = { filter: 'keyword', value }
        tags.push(tag)
      })

      if (start_date && end_date) {
        const startSplit = start_date.split('-')
        const endSplit = end_date.split('-')
        const start = `${startSplit[2]}.${startSplit[1]}.${startSplit[0].substring(2, 4)}`
        const end = `${endSplit[2]}.${endSplit[1]}.${endSplit[0].substring(2, 4)}`
        tags.push({
          filter: 'duration',
          value: `${start} - ${end}`
        })
      }

      if (submitted_start_date && submitted_end_date) {
        const startSplit = submitted_start_date.split('-')
        const endSplit = submitted_end_date.split('-')
        const start = `${startSplit[2]}.${startSplit[1]}.${startSplit[0].substring(2, 4)}`
        const end = `${endSplit[2]}.${endSplit[1]}.${endSplit[0].substring(2, 4)}`
        tags.push({
          filter: 'submitted',
          value: `${start} - ${end}`
        })
      }
      return tags
    },
    finalFilter ({ filter, selectedTab }, getters, rootState, rootGetters) {
      const finalFilter = cloneDeep(filter)
      if (!getters.hasAnyFilterApplied) {
        const { pending, rejected, approved } = flatRatePaymentStatuses
        switch (selectedTab) {
        case flatRatePaymentFilterTabs.pending:
          finalFilter.status = [pending]
          break
        case flatRatePaymentFilterTabs.rejected:
          finalFilter.status = [rejected]
          break
        case flatRatePaymentFilterTabs.approved:
          finalFilter.status = [approved]
          break
        }
      }
      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    switchTab (state, tab) {
      state.selectedTab = tab
    },
    updateFilter (state, { start_date, end_date, submitted_start_date, submitted_end_date, freelancers, projects, agencies, status }) {
      state.filter.start_date = start_date
      state.filter.end_date = end_date
      state.filter.submitted_start_date = submitted_start_date
      state.filter.submitted_end_date = submitted_end_date
      state.filter.freelancers = freelancers
      state.filter.projects = projects
      state.filter.agencies = agencies
      state.filter.status = status
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value, id }) {
      if (state.filter[filter]) {
        if (filter === 'projects' || filter === 'freelancers' || filter === 'agencies') {
          const index = state.filter[filter].findIndex(item => id === item.id)
          index !== -1 && state.filter[filter].splice(index, 1)
        } else if (filter === 'status') {
          const index = state.filter[filter].findIndex(item => flatRatePaymentStatuses[value] === item)
          index !== -1 && state.filter[filter].splice(index, 1)
        } else {
          const index = state.filter[filter].findIndex(item => value === item)
          index !== -1 && state.filter[filter].splice(index, 1)
        }
      } else if (filter === 'duration') {
        state.filter.end_date = null
        state.filter.start_date = null
      } else if (filter === 'submitted') {
        state.filter.submitted_end_date = null
        state.filter.submitted_start_date = null
      }
    }
  },
  actions: {
    switchTab ({ commit, dispatch }, tab) {
      commit('switchTab', tab)
      dispatch('flatRatePayments/reloadItems', null, { root: true })
    },
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('flatRatePayments/reloadItems', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('flatRatePayments/reloadItems', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('flatRatePayments/reloadItems', null, { root: true })
    }
  }
}
