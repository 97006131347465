import Vue from 'vue'
import generateUid from '@/helpers/uid'

Vue.component('TextAreaField', {
  functional: true,
  render (createElement, context) {
    const { attrs, props } = context.data
    if (attrs?.rules) {
      const vid = generateUid(attrs.name)
      return createElement('ValidationProvider', {
        props: {
          vid,
          name: attrs.name,
          rules: attrs.rules
        },
        scopedSlots: {
          default: function ({ errors }) {
            return createElement('v-textarea', {
              ...context.data,
              attrs: {
                ...attrs,
                rules: []
              },
              props: {
                ...props,
                outlined: true,
                filled: true,
                dense: true,
                'no-resize': true,
                'error-messages': errors
              }
            }, context.children)
          }
        }
      })
    }
    return createElement('v-textarea', {
      ...context.data,
      attrs: {
        ...attrs,
        rules: []
      },
      props: {
        ...props,
        outlined: true,
        filled: true,
        dense: true,
        'no-resize': true
      }
    }, context.children)
  }
})
