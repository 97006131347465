/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const state = () => ({
  filter: {
    keyword: [],
    start_date: null,
    end_date: null
  }
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      const { keyword, start_date, end_date } = filter
      const result = keyword.length || (start_date && end_date)
      return !!result
    },
    filterTags ({ filter }) {
      const { keyword, start_date, end_date } = filter
      const tags = []

      keyword.forEach(value => {
        const tag = { filter: 'keyword', value }
        tags.push(tag)
      })

      if (start_date && end_date) {
        tags.push({
          filter: 'registration',
          value: `${moment(start_date).format('DD.MM.YY')} - ${moment(end_date).format('DD.MM.YY')}`
        })
      }

      return tags
    },
    finalFilter ({ filter }) {
      const finalFilter = cloneDeep(filter)

      if (!finalFilter.start_date || !finalFilter.end_date) {
        delete finalFilter.start_date
        delete finalFilter.end_date
      }

      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    updateFilter (state, { start_date, end_date }) {
      state.filter.start_date = start_date
      state.filter.end_date = end_date
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value }) {
      if (state.filter[filter]) {
        const index = state.filter[filter].findIndex(item => value === item)
        index !== -1 && state.filter[filter].splice(index, 1)
      } else if (filter === 'registration') {
        state.filter.end_date = null
        state.filter.start_date = null
      }
    }
  },
  actions: {
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('agencies/reloadAgencies', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('agencies/reloadAgencies', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('agencies/reloadAgencies', null, { root: true })
    }
  }
}
