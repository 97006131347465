/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep'
import { addSearchEvent } from '@/helpers/gtm'

const state = () => ({
  filter: {
    keyword: []
  }
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      return !!filter.keyword.length
    },
    filterTags ({ filter }) {
      const tags = []

      filter.keyword.forEach(value => {
        const tag = { filter: 'keyword', value }
        tags.push(tag)
      })

      return tags
    },
    finalFilter ({ filter }) {
      return cloneDeep(filter)
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value, id }) {
      if (state.filter[filter]) {
        const index = state.filter[filter].findIndex(item => value === item)
        index !== -1 && state.filter[filter].splice(index, 1)
      }
    }
  },
  actions: {
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('reports/reloadReports', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('reports/reloadReports', null, { root: true })
    }
  }
}
