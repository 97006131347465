<template>
  <ValidationProvider
    ref="provider"
    :tag="inline ? 'span' : 'div'"
    :rules="rules"
    :name="name"
    v-slot="{ errors }"
  >
    <div
      :class="[
      'checkbox',
      themeClass,
      {
        'checkbox__small': small,
        'checkbox__secondary': secondary,
        'checkbox__inline': inline,
        'checkbox__reverse': reverse,
        'checkbox__disabled': disabled,
        'checkbox__invalid': errors.length,
        'selected': isSelected,
        'is-active': clicked
      }
    ]"
      tabindex="0"
      @click="toggle(true)"
      @keyup.space="toggle(false)"
      @focusout="clicked = false"
    >
      <div class="checkbox__bg primary"></div>
      <div
        class="checkbox__input primary"
        :class="{ 'opacity-12': !isSelected, 'opacity-64': isSelected && disabled }"
      >
        <v-icon
          small
          v-show="isSelected"
          color="secondary"
        >done</v-icon>
      </div>
      <div
        v-if="label"
        class="checkbox__label label-1"
      >
        {{ label }}
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CheckboxField',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: [Boolean, Number, Array],
      default: false
    },
    value: {
      type: [Boolean, String, Number],
      default: false
    },
    name: {
      type: String,
      default: 'checkbox'
    },
    label: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: ''
    }
  },
  data () {
    return {
      clicked: false
    }
  },
  computed: {
    ...mapGetters('theme', ['themeClass']),
    isSelected () {
      if (Array.isArray(this.selected)) {
        return this.selected.includes(this.value)
      }
      return this.selected
    }
  },
  methods: {
    toggle (isClick) {
      if (this.disabled) {
        return
      }
      this.clicked = isClick
      if (Array.isArray(this.selected)) {
        if (this.isSelected) {
          const index = this.selected.findIndex((val) => val === this.value)
          this.selected.splice(index, 1)
        } else {
          this.selected.push(this.value)
        }
        this.$refs.provider.syncValue(this.selected)
      } else {
        const value =
          typeof this.selected === 'number' ? +!this.selected : !this.selected
        this.$emit('change', value)
        this.$refs.provider.syncValue(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/variables.scss";
  @import "@/scss/mixins.scss";

  .checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 8px;
    padding: 18px 13px;
    position: relative;

    &__inline {
      display: inline-flex;
    }

    &__reverse {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &.theme--dark:not(&__secondary) {
      border: solid 1px rgba(map-get($material-dark, "colors", "primary"), 0.16);
    }

    &.theme--dark.selected:not(&__secondary) {
      border: solid 1px rgba(map-get($material-dark, "colors", "primary"), 1);
    }

    &.theme--light:not(&__secondary) {
      border: solid 1px rgba(map-get($material-light, "colors", "primary"), 0.16);
    }

    &.theme--light.selected:not(&__secondary) {
      border: solid 1px rgba(map-get($material-light, "colors", "primary"), 1);
    }

    &__invalid.theme--dark {
      border-color: map-get($material-dark, "colors", "error") !important;
    }

    &__invalid.theme--light {
      border-color: map-get($material-light, "colors", "error") !important;
    }

    &__small {
      height: 32px;
      padding: 10px 5px;
      border-radius: 6px;
    }

    &__disabled {
      cursor: default;
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }

    &:focus::after {
      content: "";
      position: absolute;
      @include -inset-2;
    }

    &.theme--dark:focus::after {
      box-shadow: 0 0 0 1px map-get($material-dark, "colors", "focus");
    }

    &.theme--light:focus::after {
      content: "";
      box-shadow: 0 0 0 1px map-get($material-light, "colors", "focus");
    }

    &.is-active::after {
      box-shadow: none !important;
    }

    &__bg {
      border-radius: inherit;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: opacity 0.3s ease;
      opacity: 0;
      pointer-events: none;
    }

    &:hover &__bg,
    &:focus &__bg {
      opacity: 0.08;
    }

    &.is-active:not(:hover) &__bg {
      opacity: 0 !important;
    }

    &__input {
      width: 12px;
      height: 12px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0 5px;

      ::v-deep .v-icon {
        font-size: 10px !important;
      }
    }

    &__label {
      font-weight: 400;
      margin: 0 5px;
      position: relative;
    }
  }
</style>
