import axios from 'axios'
import uniqBy from 'lodash/uniqBy'
import { themes } from '@/helpers/constants'

const state = () => ({
  absence: [],
  profile: {},
  jobTitles: [],
  skills: [],
  languages: [],
  projects: [],
  vatList: []
})

export default {
  namespaced: true,
  state,
  getters: {
    isProfileComplete: ({ profile }) => profile.is_profile_complete
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    setProfileData (state, data) {
      const startYear = data.experience_start_year
      state.profile = {
        ...data,
        is_profile_complete: data.is_profile_complete === 1,
        is_invoice_address_same_residential: data.is_invoice_address_same_residential === 'true',
        rate: parseFloat(data.rate),
        hourly_rate: parseFloat(data.hourly_rate),
        experience_start_year: startYear && startYear !== 'NaN' ? parseInt(startYear) : '',
        exclude_vat: data.exclude_vat === 'true',
        include_company_name: data.include_company_name === 'true'
      }
    },
    setJobTitles (state, data) {
      state.jobTitles = uniqBy([...state.jobTitles, ...data], 'id')
    },
    setSkills (state, data) {
      state.skills = uniqBy([...state.skills, ...data], 'id')
    },
    setLanguages (state, data) {
      state.languages = uniqBy([...state.languages, ...data], 'id')
    },
    setProjects (state, data) {
      state.projects = uniqBy([...state.projects, ...data], 'id')
    },
    setAbsenceData (state, data) {
      state.profile.absence = [...state.profile.absence, ...data]
    },
    removeAbsenceData (state, data) {
      state.profile.absence = state.profile.absence.filter(({ id }) => !data.includes(id))
    },
    updateBookingStatus (state, booking) {
      const item = state.profile.bookings.find(({ id }) => booking.id === id)
      if (item) {
        item.status = booking.status
      } else {
        state.profile.bookings.splice(0, 0, booking)
      }
    },
    updateBookingHoursLeft (state, { bookingId, hours }) {
      const item = state.profile.bookings.find(({ id }) => bookingId === id)
      if (item) {
        item.hours_left -= hours
      }
    },
    setVatList (state, value) {
      state.vatList = value
    }
  },
  actions: {
    createTitle ({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}title`, {
          name: search
        })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setJobTitles', [data.data])
              resolve(data.data)
            } else {
              reject(new Error('Error creating new job title'))
            }
          })
          .catch(() => {
            reject(new Error('Error creating new job title'))
          })
      })
    },
    searchTitles ({ commit }, search) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('keyword', search)
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}title?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setJobTitles', data.data.data)
              resolve()
            } else {
              reject(new Error('Job Title loading failed'))
            }
          })
          .catch(() => {
            reject(new Error('Job Title loading failed'))
          })
      })
    },
    searchSkills ({ commit }, search) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('keyword', search)
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}skill?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              const result = data.data.data
              commit('setSkills', result)
              resolve()
            } else {
              reject(new Error('Skills loading failed'))
            }
          })
          .catch(() => {
            reject(new Error('Skills loading failed'))
          })
      })
    },
    createSkill ({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}skill`, {
          name: search
        })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setSkills', [data.data])
              resolve(data.data)
            } else {
              reject(new Error('Error creating skill'))
            }
          })
          .catch(() => {
            reject(new Error('Error creating skill'))
          })
      })
    },
    searchLanguages ({ commit }, search) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('keyword', search)
        params.append('page', 1)
        params.append('items', 50)
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}spoken-languages?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setLanguages', data.data.data)
              resolve()
            } else {
              reject(new Error('Error searching language'))
            }
          })
          .catch(() => {
            reject(new Error('Error searching language'))
          })
      })
    },
    createLanguage ({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}spoken-language`, {
          language: search
        })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setLanguages', [data.data])
              resolve(data.data)
            } else {
              reject(new Error('Error creating language'))
            }
          })
          .catch(() => {
            reject(new Error('Error creating language'))
          })
      })
    },
    searchProjects ({ commit }, search) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('keyword', search)
        params.append('page', 1)
        params.append('items', 50)
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}projects?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              const result = data.data.data
              commit('setProjects', result)
              resolve()
            } else {
              reject(new Error('Error searching projects'))
            }
          })
          .catch(() => {
            reject(new Error('Error searching projects'))
          })
      })
    },
    createProject ({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}project`, {
          title: search
        })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setProjects', [data.data])
              resolve(data.data)
            } else {
              reject(new Error('Error creating project'))
            }
          })
          .catch(() => {
            reject(new Error('Error creating project'))
          })
      })
    },
    loadProfile ({ commit, dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}profile`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setProfileData', data.data)
              const {
                first_name: firstName = '',
                last_name: lastName = '',
                image_thumb: thumb,
                unread_to_do_count: todoCount = 0,
                unread_info_count: infoCount = 0,
                unread_message_count: messageCount = 0
              } = data.data
              commit('auth/SET_PROFILE_IMAGE', thumb, { root: true })
              commit('notification/updateNotificationCount', { todoCount, infoCount, messageCount }, { root: true })
              commit('auth/UPDATE_AUTH_DATA', { firstName, lastName }, { root: true })
              if (!data?.data?.settings) {
                dispatch('saveSettings')
              } else {
                const { theme, account_language: language } = data.data.settings
                dispatch('theme/compareSettings', theme, { root: true })
                dispatch('lang/compareSettings', language, { root: true })
              }
            }
            resolve()
          })
      })
    },
    saveProfile ({ commit }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()

        Object.keys(form).forEach(key => {
          const val = form[key]
          if (Array.isArray(val)) {
            val.forEach(item => {
              if (item) {
                params.append(`${key}[]`, item)
              }
            })
          } else {
            params.append(key, val)
          }
        })

        axios.put(`${process.env.VUE_APP_FREELANCER_API_URL}profile`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              const { first_name: firstName, last_name: lastName } = data.data
              commit('setProfileData', data.data)
              commit('auth/SET_PROFILE_IMAGE', data.data.image_thumb, { root: true })
              commit('auth/UPDATE_AUTH_DATA', { firstName, lastName }, { root: true })
              resolve()
            } else {
              reject(new Error('Error saving profile'))
            }
          })
          .catch(() => {
            reject(new Error('Error saving profile'))
          })
      })
    },
    saveSettings ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        const { isDark = false } = rootState.theme
        const { locale } = rootState.lang
        const params = new FormData()
        params.append('theme', isDark ? themes.dark : themes.light)
        params.append('account_language', locale)

        axios.put(`${process.env.VUE_APP_FREELANCER_API_URL}settings`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => { })
          .catch(() => {
            reject(new Error('Error saving settings'))
          })
      })
    },
    addAbsence ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        for (let i = 0; i < payload.length; i++) {
          params.append('absent_dates[]', payload[i])
        }
        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}absence`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setAbsenceData', data.data)
              resolve(data)
            } else {
              reject(new Error('Error on Adding Absence Dates'))
            }
          })
          .catch(() => {
            reject(new Error('Error on Adding Absence Dates'))
          })
      })
    },
    removeAbsence ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        for (let i = 0; i < payload.length; i++) {
          params.append('ids[]', payload[i])
        }
        axios.delete(`${process.env.VUE_APP_FREELANCER_API_URL}absence?${params.toString()}`,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            commit('removeAbsenceData', payload)
            resolve(data)
          })
          .catch(() => {
            reject(new Error('Error on Removing Absence Dates'))
          })
      })
    },
    deleteAccount () {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_FREELANCER_API_URL}delete-account`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve()
            } else {
              reject(new Error('Error on deleting account'))
            }
          })
          .catch(() => {
            reject(new Error('Error on deleting account'))
          })
      })
    },
    loadChart ({ commit }, year) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}income/chart/yearly/${year}`)

          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              resolve(data)
            } else {
              reject(new Error('Chart Data loading failed'))
            }
          })
          .catch(() => {
            reject(new Error('Chart Data loading failed'))
          })
      })
    },
    loadChartMobile ({ commit }, { month, year }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}income/chart/monthly/${parseFloat(month)}/year/${year}`)
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              resolve(data)
            } else {
              reject(new Error('Mobile Chart Data loading failed'))
            }
          })
          .catch(() => {
            reject(new Error('Mobile Chart Data loading failed'))
          })
      })
    },
    loadVatList ({ state, commit }) {
      if (state.vatList.length) {
        return
      }
      axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}vat`)
        .then(({ data }) => {
          if (data.status === 'success') {
            commit('setVatList', data.data)
          }
        })
    }
  }
}
