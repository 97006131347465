<template>
  <div :class="['button-field', `button-field__${slot}`]">
    <TextField
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:[slot]>
        <v-btn
          :disabled="invalid || loading"
          :loading="loading"
          small
          color="primary"
          @click.prevent="$emit('click:button')"
        >
          {{ btnText }}
        </v-btn>
      </template>
    </TextField>
  </div>
</template>

<script>
export default {
  name: 'ButtonField',
  props: {
    invalid: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      required: true
    },
    prepend: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      slot: this.prepend ? 'prepend-inner' : 'append'
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-field {
    &__append {
      ::v-deep {
        .v-text-field > .v-input__control > .v-input__slot {
          padding-right: 8px !important;

          .v-input__append-inner {
            margin-top: 8px !important;
          }
        }
      }
    }

    &__prepend-inner {
      ::v-deep {
        .v-text-field > .v-input__control > .v-input__slot {
          padding-left: 8px !important;

          .v-input__prepend-inner {
            margin-top: 8px !important;
          }
        }
      }
    }
  }
</style>
