<template>
  <div
    class="toggle-switch"
    tabindex="0"
    @click.prevent="toggleVal"
    @keyup.space="toggleVal"
  >
    <div
      class="toggle-switch__wrapper rounded-pill"
      ref="wrapper"
    >
      <div
        class="toggle-switch__selected-bg primary rounded-pill"
        :style="selecteStyle"
      ></div>
      <v-sheet
        v-for="(item, index) in items"
        :key="item"
        :ref="`item-${index}`"
        rounded="pill"
        :class="item === selected ? 'text--secondary' : 'text--primary'"
      >
        {{ $t(`common.${item}`) }}
      </v-sheet>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    value: {
      type: [String, Boolean, Number],
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selecteStyle: {}
    }
  },
  computed: {
    selected () {
      return this.value
    },
    selectedIndex () {
      return this.items.findIndex(item => item === this.selected)
    }
  },
  watch: {
    selectedIndex () {
      this.setSelectedStyle()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setSelectedStyle()
    })
  },
  methods: {
    toggleVal () {
      const val = this.items.find(item => item !== this.selected)
      this.$emit('input', val)
    },
    setSelectedStyle () {
      const item = this.$refs[`item-${this.selectedIndex}`]
      if (item) {
        const width = item[0].$el.clientWidth
        this.selecteStyle = {
          width: `${width}px`,
          left: `${
            !this.selectedIndex
              ? 2
              : this.$refs.wrapper.clientWidth - (width + 2)
          }px`
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/mixins.scss";

  .toggle-switch {
    border-radius: 9999px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    outline: none;

    &::after {
      content: "";
      position: absolute;
      border: solid 1px transparent;
      @include -inset-2;
    }

    &__wrapper {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      position: relative;
    }

    ::v-deep .v-sheet {
      @include label-1;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 16px;
      position: relative;
      z-index: 1;
      transition: color 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    }

    &__selected {
      &-bg {
        height: 32px;
        pointer-events: none;
        position: absolute;
        left: 2px;
        top: 2px;
        z-index: 0;
        transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
  }
</style>
