<template>
  <div>
    <v-chip
      @click="updateToggle(selected)"
      :class="checkIcon ? 'chipIcon' : ' customChipClass'"
      :color="value ? 'primary' : 'neutral'"
    >
      <v-icon
        v-if="leadingIcon !== ''"
        size="13"
        :class="[checkFontColor, !checkIcon ? 'mr-1' : '']"
        >{{ leadingIcon }}</v-icon
      >
      <div
        :class="checkFontColor"
      >
        {{text}}
      </div>
      <v-icon
        v-if="trailingIcon !== ''"
        size="13"
        :class="[checkFontColor, !checkIcon ? 'ml-1' : '']"
        >{{ trailingIcon }}</v-icon
      >
    </v-chip>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    text: {
      default: '',
      type: String
    },
    leadingIcon: {
      default: '',
      type: String
    },
    trailingIcon: {
      default: '',
      type: String
    }
  },
  computed: {
    selected () {
      return this.value
    },
    checkFontColor () {
      if (this.value) {
        return 'secondary--text'
      } else {
        return 'primary--text'
      }
    },
    checkIcon () {
      if ((this.leadingIcon !== '' || this.trailingIcon !== '') && this.text === '') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    updateToggle (val) {
      val = !val
      this.$emit('input', val)
    }
  }
}
</script>
<style scoped>
.customChipClass {
  min-width: 32px !important;
}
.chipIcon {
  width: 32px !important;
}
.chipIcon i {
  width: 100% !important;
  text-align: center;
}
</style>
