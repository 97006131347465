export default {
  light: {
    primary: {
      base: '#232425'
    },
    secondary: '#FFFFFF',
    accent: '#79D88E',
    success: '#048564',
    error: '#BA2344',
    neutral: '#D6CEC0',
    tertiary: '#E8E3D9',
    focus: '#7B5C4E',
    'app-bg': '#F2EFE9'
  },
  dark: {
    primary: {
      base: '#FFFFFF'
    },
    secondary: '#232425',
    accent: '#5DB37E',
    success: '#5DB37E',
    error: '#FF8484',
    neutral: '#1A1A1A',
    tertiary: '#101010',
    focus: '#FFC684',
    'app-bg': '#000000'
  }
}
