<template>
  <div class="logo">
    <div class="body-1 logo__headline">
      <slot></slot>
    </div>
    <img
      :src="require(`@/assets/${logo}.svg`)"
      alt="Aladin logo"
    />
  </div>
</template>

<script>
export default {
  computed: {
    logo () {
      return `logo-${this.$store.state.theme.isDark ? 'light' : 'dark'}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    &__headline:not(:empty) {
      margin-bottom: 12px;
    }

    img {
      width: 136px;
      transition: all 0.3s ease;

      @media (min-width: 600px) {
        width: 320px;
      }

      @media (min-width: 960px) {
        width: 136px;
      }

      @media (min-width: 1200px) {
        width: 320px;
      }
    }
  }
</style>
