import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'

const state = () => ({
  conversationList: [],
  conversationListLoading: false,
  userMessages: {},
  userMessagesLoading: false,
  searchUsers: [],
  selectedUser: {}
})

export default {
  namespaced: true,
  state,
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    SET_CONVERSATION_LIST (state, data) {
      state.conversationList = data
    },
    SET_USER_MESSAGE (state, data) {
      state.userMessages = data
    },
    SET_MESSAGE_LOADING (state, data) {
      state.userMessagesLoading = data
    },
    SET_APPEND_USER_MSG (state, data) {
      state.userMessages = [...data, ...state.userMessages]
    },
    SET_SEARCH_USER (state, data) {
      state.searchUsers = data
    },
    SET_CONVERSATION_LOADING (state, data) {
      state.conversationListLoading = data
    },
    SET_SELECTED_USER (state, data) {
      state.selectedUser = data
    },
    SET_NEW_USER_MSG (state, data) {
      const { selectedUserData, index } = data
      state.conversationList[index] = selectedUserData
    },
    SET_LOADING_STATUS (state, data) {
      const { status, index } = data
      state.conversationList[index].loading = status
    },
    APPEND_USER_CONVERSATION (state, data) {
      state.conversationList.unshift(data)
    }
  },
  actions: {
    async loadConversationList ({ state, commit, dispatch }) {
      commit('SET_USER_MESSAGE', [])
      commit('SET_SEARCH_USER', [])
      commit('SET_CONVERSATION_LOADING', true)
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_INMAIL_URL}conversation/user_list`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('SET_CONVERSATION_LOADING', false)
              const newData = data.data.map(item => ({ ...item, loading: false, sendMsgLoading: false, last_message_at: moment.utc(item.last_message_at).local().format('hh:mm A') }))
              commit('SET_CONVERSATION_LIST', newData)
              resolve()
            } else {
              reject(new Error('Error loading conversation'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading conversation'))
          })
      })
    },
    async userMessage ({ state, commit, dispatch }, data) {
      const { conversation_id, page, items } = data
      if (page === 1) {
        commit('SET_USER_MESSAGE', [])
      }
      commit('SET_MESSAGE_LOADING', true)
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_INMAIL_URL}conversation/messages`, { conversation_id: conversation_id, page: page, items: items })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('SET_MESSAGE_LOADING', false)
              data.data = data.data.map(item => ({
                ...item,
                created_date: moment.utc(item.created_time).local().format('DD.MM.yyyy'),
                created_time: moment.utc(item.created_time).local().format('hh:mm A'),
                created_at: moment.utc(item.created_time).local()
              }))
              resolve(data)
            } else {
              reject(new Error('Error loading messages'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading messages'))
          })
      })
    },
    async sendUserMessage ({ state, commit, dispatch }, data) {
      const { msg, userId } = data
      const conversationId = data.conversation_id
      return new Promise((resolve, reject) => {
        const sendData = { message: msg }
        if (conversationId === null && userId !== undefined) {
          sendData.receiver_id = userId
        } else {
          sendData.conversation_id = conversationId
        }
        axios.post(`${process.env.VUE_APP_INMAIL_URL}send/message`, sendData)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              reject(new Error('Error sending message'))
            }
          })
          .catch(() => {
            reject(new Error('Error sending message'))
          })
      })
    },
    async searchUserFun ({ state, commit, dispatch }, data) {
      const { search } = data

      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_INMAIL_URL}users/search`, {
          params: {
            keyword: search
          }
        })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('SET_SEARCH_USER', data.data)
              resolve()
            } else {
              reject(new Error('Error searching users'))
            }
          })
          .catch(() => {
            reject(new Error('Error searching users'))
          })
      })
    },
    async clearMessage ({ state, commit, dispatch }) {
      commit('SET_USER_MESSAGE', [])
    },
    async updateMessage ({ state, commit, dispatch }, data) {
      commit('SET_USER_MESSAGE', data)
    },
    async changeMsgLoading ({ state, commit, dispatch }, data) {
      commit('SET_MESSAGE_LOADING', data)
    },
    async setConversation ({ state, commit, dispatch }, convData) {
      commit('SET_SELECTED_USER', convData)
      if (convData.conversation_id !== null && (convData.messages === undefined || convData.isPagination) && !convData.loading) {
        const objData = { conversation_id: convData.conversation_id, page: convData.page, items: 100 }
        const index = state.conversationList.findIndex((conv) => {
          return conv.conversation_id === objData.conversation_id
        })
        let loadingStatus = {
          status: true,
          index
        }
        commit('SET_LOADING_STATUS', loadingStatus)
        dispatch('userMessage', objData).then((data) => {
          if (index !== undefined && state.conversationList[index]) {
            loadingStatus = {
              status: false,
              index
            }
            commit('SET_LOADING_STATUS', loadingStatus)
            const selectedUserData = state.conversationList[index]
            Vue.set(selectedUserData, 'page', data.total_pages)
            Vue.set(selectedUserData, 'isPagination', false)
            if (selectedUserData.messages === undefined) {
              Vue.set(selectedUserData, 'messages', data.data)
            } else {
              selectedUserData.messages.unshift(...data.data)
            }
            const appendMsg = {
              selectedUserData: selectedUserData,
              index
            }
            commit('SET_SELECTED_USER', selectedUserData)
            commit('SET_NEW_USER_MSG', appendMsg)
          }
        })
      }
      if (convData.messages !== undefined && convData.messages.length > 0) {
        dispatch('updateMessage', convData.messages)
      }
    },
    async loadUserChat ({ state, commit, dispatch }, email) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_INMAIL_URL}users/search`, {
          params: {
            keyword: email
          }
        })
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              reject(new Error('Error loading messenger user'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading messenger user'))
          })
      })
    },
    async reloadUserMsg ({ state, commit, dispatch }, data) {
      if (data.conversation_id) {
        const index = state.conversationList.findIndex(item => {
          return item.conversation_id === data.conversation_id
        })
        if (index === -1) {
          data.loading = false
          data.sendMsgLoading = false
          commit('APPEND_USER_CONVERSATION', data)
        }
      }
      await dispatch('setConversation', data)
    },
    clearSearch ({ commit }) {
      commit('SET_SEARCH_USER', [])
    }
  }
}
