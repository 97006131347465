<template>
  <div :class="[flex ? `d-${flex}-flex` : 'd-flex', `flex-${direction}`, `align-${align}`, `justify-${justify}`]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    flex: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: 'row'
    },
    align: {
      type: String,
      default: 'center'
    },
    justify: {
      type: String,
      default: 'start'
    }
  }
}
</script>
