import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { extend } from 'vee-validate'
import { regex } from 'vee-validate/dist/rules'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('../locales', false, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const messages = loadLocaleMessages()

function checkDefaultLanguage () {
  let matched = null
  const languages = Object.getOwnPropertyNames(loadLocaleMessages())
  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang
    }
  })
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0]
      if (lang.split('-')[0] === languagePartials) {
        matched = lang
      }
    })
  }
  return matched
}

export const selectedLocale = checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'en'
// export const languages = Object.getOwnPropertyNames(messages)

const i18n = new VueI18n({
  messages,
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentFallbackWarn: true
})

// vee-validate

extend('regex', {
  ...regex,
  message: (_, values) => i18n.t(`form.messages.${values._field_}`, values)
})

export default i18n
