import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import Cookies from 'js-cookie'
import { realms } from '@/helpers/constants'

import App from './App.vue'
// import './registerServiceWorker'

// For load all routes
import router from './router'
import pages from './router/routes'

// For Load vuex store data
import store from './store'

import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import './plugins/axios'
import i18n from './plugins/i18n'
// import keycloak from './plugins/keycloak'

// For register components globally
import './globalComponents'
import './plugins/filter'
const isProduction = process.env.NODE_ENV === 'production'
const loggerOptions = {
  isEnabled: !isProduction,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: !isProduction,
  showMethodName: !isProduction,
  separator: '|',
  showConsoleColors: true
}

Vue.config.productionTip = false

Vue.use(VueLogger, loggerOptions)

const { locale } = store.state.lang

// set previously selected lang
if (locale && locale !== i18n.locale) {
  i18n.locale = locale
}

const refreshToken = Cookies.get('refreshToken')
if (!refreshToken) {
  store.commit('auth/resetStore')
}

window.onfocus = () => {
  store.dispatch('auth/validateTokenExpiry')
}

const loadProfile = () => {
  const { auth } = store.state
  if (!auth?.accessToken || !auth?.realm) {
    if (!auth?.redirectUrl) {
      const requestUrl = window.location.hash.replace('#', '')
      const urls = [pages.projects, pages.freelancers, pages.agencies, pages.timeRecording, pages.creditNotes, pages.users, pages.profile, pages.settings]
      urls.forEach(({ path }) => {
        if (requestUrl.includes(path)) {
          store.commit('auth/updateRedirectUrl', requestUrl)
        }
      })
    }

    return Promise.resolve()
  }

  const module = auth.realm === realms.freelancer ? 'freelancer' : 'agency'

  if (auth?.data?.groups?.length && !auth?.activeGroup) {
    return Promise.resolve()
    // store.commit('auth/setActiveGroup', auth.data.groups[0])
  }

  return store.dispatch(`${module}/loadProfile`)
}

const { isDark = false } = vuetify.framework.theme
const setBg = () => {
  const { themes } = vuetify.framework.theme
  const theme = isDark ? 'dark' : 'light'
  const bgColor = themes[theme]['app-bg']
  document.body.style.backgroundColor = bgColor
  // update manifest theme color meta
  const color = themes[theme].primary.base
  store.dispatch('theme/updateMeta', color)
  return Promise.resolve()
}

const initApp = () => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

setBg()
  .then(() => {
    store.dispatch('auth/validateTokenExpiry')
  })
  .then(loadProfile)
  .then(initApp)
