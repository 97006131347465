import axios from 'axios'
import { itemsPerPage } from '@/helpers/constants'
import moment from 'moment'
import debounce from 'lodash/debounce'
import convertToFormData from '@/helpers/convertToFormData'

const state = () => ({
  perPageItems: itemsPerPage,
  currentPage: 1,
  loading: false,
  sorting: {
    by: 'created_at',
    order: 'DESC'
  },
  timeRecording: [],
  total: null
})

export default {
  namespaced: true,
  state,
  getters: {

  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setItems (state, data) {
      state.timeRecording = data
    },
    updatePerPageItems (state, val) {
      state.perPageItems = val
    },
    setPage (state, data) {
      state.currentPage = data
    },
    updateSort (state, data) {
      state.sorting = data
    },
    resetItems (state) {
      state.currentPage = 1
      state.timeRecording = []
    },
    setTotal (state, data) {
      state.total = data
    },
    updateStatus (state, data) {
      const item = state.timeRecording.find(({ id }) => id === data.time_record_id)
      if (item) {
        item.status = data.status
      }
    }
  },
  actions: {
    loadTimeRecording: debounce(function ({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('items', state.perPageItems)
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)
        params.append('page', state.currentPage)

        const filters = rootGetters['timeRecordingFilter/finalFilter']
        Object.keys(filters).forEach(key => {
          const val = filters[key]
          if (Array.isArray(val)) {
            if (['projects', 'freelancers', 'agencies'].includes(key)) {
              val.forEach((item) => {
                params.append(`${key}[]`, item.id)
              })
            } else {
              val.forEach((item) => {
                params.append(`${key}[]`, item)
              })
            }
          } else if (val) {
            params.append(key, val)
          }
        })
        commit('toggleLoading', true)
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}time-record?${params.toString()}`)
          .then(({ data }) => {
            commit('toggleLoading', false)
            if (data.status === 'success') {
              const timeRecording = data.data.data.map(item => ({ ...item, submitted_date: moment(item.created_at).format('DD.MM.YYYY') }))
              commit('setItems', timeRecording)
              commit('setTotal', data.data.total)
              resolve(timeRecording)
            } else {
              reject(new Error('Error loading time recording detail'))
            }
          }).catch((error) => {
            commit('toggleLoading', false)
            reject(error)
          })
      })
    }, 100),
    createTimeRecording ({ commit, dispatch }, { form, hoursToReduce }) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(form).forEach(key => {
          const val = form[key]
          params.append(key, Array.isArray(val) ? JSON.stringify(val) : val)
        })
        axios[form.id ? 'put' : 'post'](`${process.env.VUE_APP_FREELANCER_API_URL}time-record`, params)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('freelancer/updateBookingHoursLeft', { bookingId: form.booking_id, hours: hoursToReduce }, { root: true })
              dispatch('loadTimeRecording')
              resolve(data)
            } else {
              reject(new Error('Error saving time recording'))
            }
          })
          .catch(() => {
            reject(new Error('Error saving time recording'))
          })
      })
    },
    async updateTimeRecording ({ commit, dispatch }, option) {
      await commit('setPage', option.page)
      await commit('updatePerPageItems', option.itemsPerPage)
      await dispatch('loadTimeRecording')
    },
    async sortTimeRecording ({ commit, dispatch }, sort) {
      await commit('updateSort', sort)
      await dispatch('loadTimeRecording')
    },
    declineTimeRecord ({ commit }, request) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}update-time-record`, request)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('updateStatus', request)
              resolve(data)
            } else {
              reject(new Error('Error updating time record status'))
            }
          }).catch(() => {
            reject(new Error('Error updating time record status'))
          })
      })
    },
    reloadTimeRecording ({ commit, dispatch }) {
      commit('resetItems')
      dispatch('loadTimeRecording')
    },
    deleteTimeRecordingRequest ({ commit, dispatch }, id) {
      return new Promise((resolve) => {
        const params = new URLSearchParams()
        params.append('id', id)
        axios.delete(`${process.env.VUE_APP_FREELANCER_API_URL}time-record?${params.toString()}`)
          .then(({ data }) => {
            resolve(data)
          })
      })
    },
    approveTimeRecord ({ commit, dispatch }, form) {
      return new Promise((resolve, reject) => {
        const params = convertToFormData(form)

        axios.post(`${process.env.VUE_APP_FREELANCER_API_URL}time-record/split`, params)
          .then(({ data }) => {
            dispatch('loadTimeRecording')
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
