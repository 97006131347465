const amountToLocaleString = ({ amount, locale = 'de', includeCurrency = true, currency = '€', decimalZeros = true }) => {
  let result = amount.toLocaleString(locale)
  const point = locale === 'en' ? '.' : ','
  if (amount && decimalZeros) {
    if (amount % 1 === 0) {
      result = `${result}${point}00`
    } else if (result.indexOf(point) !== -1 && result.split(point)[1].length < 2) {
      result = `${result}0`
    }
  }
  if (includeCurrency) {
    result = locale === 'en' ? `${currency} ${result}` : `${result} ${currency}`
  }
  return result
}

export default amountToLocaleString
