import axios from 'axios'
import { itemsPerPage } from '@/helpers/constants'
const state = () => ({
  users: [],
  loading: false,
  currentPage: 1,
  perPageItems: itemsPerPage,
  sorting: {
    by: 'firstName',
    order: 'ASC'
  },
  total: null
})

export default {
  namespaced: true,
  state,
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    resetUsers (state) {
      state.currentPage = 1
      state.users = []
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    SET_USERS (state, data) {
      state.users = data
    },
    SET_PER_PAGE_ITEM (state, val) {
      state.perPageItems = val
    },
    SET_PAGINATION_PAGE (state, data) {
      state.currentPage = data
    },
    UPDATE_SORT (state, data) {
      state.sorting = data
    },
    SET_TOTAL_DATA (state, data) {
      state.total = data
    }
  },
  actions: {
    loadUsers ({ state, commit, rootGetters }, { search, roles = [] }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        const isSearch = typeof search !== 'undefined'
        params.append('items', state.perPageItems)
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)
        params.append('page', state.currentPage)
        if (isSearch) {
          params.append('keyword[]', search)
          roles.forEach(role => params.append('roles[]', role))
        } else {
          const filters = rootGetters['usersFilter/finalFilter']
          Object.keys(filters).forEach(key => {
            const val = filters[key]
            if (Array.isArray(val)) {
              val.forEach((item, index) => {
                params.append(`${key}[]`, item)
              })
            } else {
              params.append(key, val)
            }
          })
          commit('toggleLoading', true)
        }
        axios
          .post(`${process.env.VUE_APP_AGENCY_API_URL}admin/user-list`, params)
          .then(({ data }) => {
            commit('toggleLoading', false)
            if (data.status === 'success') {
              if (!isSearch) {
                commit('SET_USERS', data.data)
                commit('SET_TOTAL_DATA', data.total)
              }
              resolve(data.data)
            } else {
              reject(new Error('Error loading users'))
            }
          })
          .catch(() => {
            commit('toggleLoading', false)
            reject(new Error('Error loading users'))
          })
      })
    },
    reloadUsers ({ commit, dispatch }) {
      commit('resetUsers')
      dispatch('loadUsers', {})
    },
    async updateUsers ({ commit, dispatch }, option) {
      await commit('SET_PAGINATION_PAGE', option.page)
      await commit('SET_PER_PAGE_ITEM', option.itemsPerPage)
      await dispatch('loadUsers', {})
    },
    async sortUsers ({ commit, dispatch }, sort) {
      await commit('UPDATE_SORT', sort)
      await dispatch('loadUsers', {})
    },
    downloadUsers ({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)

        const filters = rootGetters['usersFilter/finalFilter']
        Object.keys(filters).forEach(key => {
          const val = filters[key]
          if (Array.isArray(val)) {
            val.forEach((item, index) => {
              params.append(`${key}[]`, item)
            })
          } else {
            params.append(key, val)
          }
        })

        axios
          .get(
            `${
              process.env.VUE_APP_AGENCY_API_URL
            }admin/user-list-download?${params.toString()}`,
            { responseType: 'blob' }
          )
          .then(({ data }) => {
            if (data) {
              resolve(data)
            } else {
              reject(new Error('Error downloading users'))
            }
          })
          .catch(() => {
            reject(new Error('Error downloading users'))
          })
      })
    }
  }
}
