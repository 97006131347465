import axios from 'axios'
import { itemsPerPage } from '@/helpers/constants'

const state = () => ({
  perPageItems: itemsPerPage,
  currentPage: 1,
  loading: false,
  sorting: {
    by: 'id',
    order: 'DESC'
  },
  total: null,
  bookings: []
})

export default {
  namespaced: true,
  state,
  getters: {

  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setItems (state, data) {
      state.bookings = data
    },
    updatePerPageItems (state, val) {
      state.perPageItems = val
    },
    setPage (state, data) {
      state.currentPage = data
    },
    updateSort (state, data) {
      state.sorting = data
    },
    resetItems (state) {
      state.currentPage = 1
      state.bookings = []
    },
    setTotal (state, data) {
      state.total = data
    },
    updateBookingStatus (state, booking) {
      const item = state.bookings.find(({ id }) => booking.id === id)
      if (item) {
        item.status = booking.status
      }
    },
    updateFlatBookingSplitPayments (state, { bookingId, amount }) {
      const item = state.bookings.find(({ id }) => id === bookingId)
      if (item) {
        item.amount_left -= amount
      }
    }
  },
  actions: {
    loadBookings ({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('items', state.perPageItems)
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)
        params.append('page', state.currentPage)

        const filters = rootGetters['bookingsFilter/finalFilter']
        Object.keys(filters).forEach(key => {
          const val = filters[key]
          if (Array.isArray(val)) {
            val.forEach((item) => {
              params.append(`${key}[]`, ['agencies', 'freelancers'].includes(key) ? item.id : item)
            })
          } else if (val) {
            params.append(key, val)
          }
        })
        commit('toggleLoading', true)
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}bookings?${params.toString()}`)
          .then(({ data }) => {
            commit('toggleLoading', false)
            if (data.status === 'success') {
              const items = data.data.data
              commit('setItems', items)
              commit('setTotal', data.data.total)
              resolve(items)
            } else {
              reject(new Error('Error loading bookings'))
            }
          }).catch((error) => {
            commit('toggleLoading', false)
            reject(error)
          })
      })
    },
    async updateBookings ({ commit, dispatch }, option) {
      await commit('setPage', option.page)
      await commit('updatePerPageItems', option.itemsPerPage)
      await dispatch('loadBookings')
    },
    async sortBookings ({ commit, dispatch }, sort) {
      await commit('updateSort', sort)
      await dispatch('loadBookings')
    },
    reloadBookings ({ commit, dispatch }) {
      commit('resetItems')
      dispatch('loadBookings')
    }
  }
}
