import i18n, { selectedLocale } from '@/plugins/i18n'
export default {
  namespaced: true,
  state: {
    locale: selectedLocale,
    languages: ['en', 'de']
  },
  getters: {

  },
  mutations: {
    updateLocale (state, newLocale) {
      state.locale = newLocale
    }
  },
  actions: {
    updateLocale ({ commit }, newLocale) {
      i18n.locale = newLocale
      commit('updateLocale', newLocale)
    },
    compareSettings ({ state, dispatch }, language) {
      const { locale } = state
      if (locale !== language) {
        dispatch('updateLocale', language)
      }
    }
  }
}
