import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import lang from './lang'
import theme from './theme'
import agency from './agency'
import agencies from './agencies'
import agenciesFilter from './agenciesFilter'
import chat from './chat'
import freelancer from './freelancer'
import freelancers from './freelancers'
import freelancersFilter from './freelancersFilter'
import projects from './projects'
import projectsFilter from './projectsFilter'
import bookings from './bookings'
import bookingsFilter from './bookingsFilter'
import notification from './notification'
import timeRecording from './timeRecording'
import timeRecordingFilter from './timeRecordingFilter'
import timeRecordingReview from './timeRecordingReview'
import timeReviewFilter from './timeReviewFilter'
import flatRatePayments from './flatRatePayments'
import flatRatePaymentsFilter from './flatRatePaymentsFilter'
import flatRateReview from './flatRateReview'
import flatRateReviewFilter from './flatRateReviewFilter'
import users from './users'
import usersFilter from './usersFilter'
import creditNote from './creditNote'
import creditNoteFilter from './creditNoteFilter'
import reports from './reports'
import reportFilter from './reportFilter'
import { actions, addNavigationEvent } from '@/helpers/gtm'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isTokenUpdating: false,
    sidepanelVisible: false,
    sidepanelDetailVisible: false,
    errors: []
  },
  getters: {
  },
  mutations: {
    toggleTokenUpdateStatus (state, status) {
      state.isTokenUpdating = status
    },
    toggleSidepanel (state) {
      state.sidepanelVisible = !state.sidepanelVisible
    },
    updateSidepanelDetailStatus (state, status) {
      state.sidepanelDetailVisible = status
    },
    addMessage (state, item) {
      const error = { ...item, id: new Date().getTime(), show: true, timeout: 3000 }
      state.errors.push(error)
      setTimeout(() => {
        const index = state.errors.findIndex(({ id }) => id === error.id)
        if (index !== -1) {
          state.errors.splice(index, 1)
        }
      }, 3200)
    }
  },
  actions: {
    toggleSidepanel ({ state, commit }) {
      if (!state.sidepanelVisible) {
        addNavigationEvent(actions.sidePanel)
      }
      commit('toggleSidepanel')
    }
  },
  modules: {
    auth,
    lang,
    theme,
    agency,
    agencies,
    agenciesFilter,
    freelancer,
    freelancers,
    freelancersFilter,
    chat,
    projects,
    projectsFilter,
    bookings,
    bookingsFilter,
    notification,
    timeRecording,
    timeRecordingFilter,
    timeRecordingReview,
    timeReviewFilter,
    users,
    usersFilter,
    creditNote,
    creditNoteFilter,
    flatRatePayments,
    flatRatePaymentsFilter,
    flatRateReview,
    flatRateReviewFilter,
    reports,
    reportFilter
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'lang', 'theme', 'freelancersFilter', 'projectsFilter', 'bookingsFilter', 'agenciesFilter', 'timeRecordingFilter', 'timeReviewFilter', 'usersFilter', 'creditNoteFilter', 'flatRatePaymentsFilter', 'flatRateReviewFilter', 'reportFilter']
    })
  ]
})
