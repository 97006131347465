<template>
  <div
    :class="['theme-switcher neutral', { 'is-active': clicked }]"
    tabindex="0"
    @click="switchTheme(true)"
    @keyup.space="switchTheme(false)"
    @focusout="clicked = false"
  >
    <v-icon class="text--primary">wb_sunny</v-icon>
    <v-icon class="text--primary">bedtime</v-icon>
    <div :class="['theme-switcher__btn primary', { 'light': !isDark }]"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      clicked: false
    }
  },
  computed: {
    ...mapState('theme', ['isDark'])
  },
  methods: {
    ...mapActions('theme', ['update']),
    switchTheme (isClick) {
      this.clicked = isClick
      const theme = !this.isDark
      const color = this.$vuetify.theme.themes[theme ? 'dark' : 'light'].primary
        .base
      this.update({ theme, color })
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/mixins.scss";

  .theme-switcher {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 62px;
    height: 32px;
    min-height: 32px;
    border-radius: 999px;
    padding: 0 8px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      border: solid 1px transparent;
      @include -inset-2;
    }

    &:focus {
      outline: none;

      &.is-active::after {
        border-color: transparent !important;
      }
    }

    &__btn {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);

      &.light {
        left: 32px;
      }
    }

    ::v-deep .v-icon.v-icon {
      font-size: 1rem;
    }
  }
</style>
