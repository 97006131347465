/* eslint-disable camelcase */
import cloneDeep from 'lodash/cloneDeep'
import { addSearchEvent, addFilterEvent } from '@/helpers/gtm'

const state = () => ({
  filter: {
    keyword: [],
    start_date: null,
    end_date: null,
    roles: [],
    languages: [],
    status: []
  }
})

export default {
  namespaced: true,
  state,
  getters: {
    hasAnyFilterApplied ({ filter }) {
      const { keyword, start_date, end_date, roles, languages, status } = filter
      const result = keyword.length || (start_date && end_date) || roles.length || languages.length || status.length
      return !!result
    },
    filterTags ({ filter }) {
      const { keyword, start_date, end_date, roles, languages, status } = filter
      const tags = []
      if (roles.length) {
        roles.forEach(value => {
          const tag = { filter: 'roles', value }
          tags.push(tag)
        })
      }
      if (languages.length) {
        languages.forEach(value => {
          const tag = { filter: 'languages', value }
          tags.push(tag)
        })
      }
      if (status.length) {
        status.forEach(value => {
          const tag = { filter: 'status', value }
          tags.push(tag)
        })
      }

      keyword.forEach(value => {
        const tag = { filter: 'keyword', value }
        tags.push(tag)
      })

      if (start_date && end_date) {
        const startSplit = start_date.split('-')
        const endSplit = end_date.split('-')
        const start = `${startSplit[2]}.${startSplit[1]}.${startSplit[0].substring(2, 4)}`
        const end = `${endSplit[2]}.${endSplit[1]}.${endSplit[0].substring(2, 4)}`
        tags.push({
          filter: 'registration',
          value: `${start} - ${end}`
        })
      }

      return tags
    },
    finalFilter ({ filter }) {
      const finalFilter = cloneDeep(filter)

      if (!finalFilter.start_date || !finalFilter.end_date) {
        delete finalFilter.start_date
        delete finalFilter.end_date
      }

      return finalFilter
    }
  },
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    updateFilter (state, { start_date, end_date, roles, languages, status }) {
      state.filter.start_date = start_date
      state.filter.end_date = end_date
      state.filter.roles = roles
      state.filter.languages = languages
      state.filter.status = status
    },
    addKeyword (state, keyword) {
      state.filter.keyword.push(keyword)
    },
    removeFilter (state, { filter, value }) {
      if (state.filter[filter]) {
        const index = state.filter[filter].findIndex(item => value === item)
        index !== -1 && state.filter[filter].splice(index, 1)
      } else if (filter === 'registration') {
        state.filter.end_date = null
        state.filter.start_date = null
      }
    }
  },
  actions: {
    updateFilter ({ commit, dispatch }, data) {
      commit('updateFilter', data)
      addFilterEvent()
      dispatch('users/reloadUsers', null, { root: true })
    },
    addKeyword ({ commit, dispatch }, keyword) {
      commit('addKeyword', keyword)
      addSearchEvent(keyword)
      dispatch('users/reloadUsers', null, { root: true })
    },
    removeFilter ({ commit, dispatch }, data) {
      commit('removeFilter', data)
      dispatch('users/reloadUsers', null, { root: true })
    }
  }
}
