<template>
  <div class="error-notifications">
    <v-snackbar
      v-for="error in errors"
      v-model="error.show"
      :key="error.id"
      :timeout="error.timeout"
      color="error"
      bottom
      right
      max-width="400"
    >
      {{ error.messageKey ? $t(error.messageKey) : error.message }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['errors'])
  }
}
</script>
