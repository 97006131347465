<template>
  <router-link
    v-bind="$attrs"
    v-on="$listeners"
    :class="['link-item label-1 font-weight-regular', { underlined }]"
  >
    <span class="link-item__icon-left">
      <slot name="prepend-icon"></slot>
    </span>
    <span class="link-item__text">
      <slot></slot>
    </span>
    <span class="link-item__icon-right">
      <slot name="append-icon"></slot>
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    underlined: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .link-item {
    text-decoration: none;

    &__text {
      position: relative;
    }

    &__text::after {
      border-bottom: solid 1px currentColor;
      content: "";
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      transition: opacity 0.3s ease;
    }

    &:hover &__text::after,
    &.underlined &__text::after {
      opacity: 0.16;
    }

    &__icon {
      &-left {
        margin-right: 5px;

        &:empty {
          margin: 0;
        }
      }

      &-right {
        margin-left: 5px;

        &:empty {
          margin: 0;
        }
      }
    }

    ::v-deep .v-icon {
      font-size: 11px;
    }
  }
</style>
