import Vue from 'vue'

Vue.component('CircleLoader', {
  functional: true,
  render (createElement, context) {
    return createElement('v-progress-circular', {
      ...context.data,
      props: {
        indeterminate: true,
        color: 'primary',
        size: context.data?.attrs?.size || 24,
        width: 2,
        ...context.data.props
      }
    }, context.children)
  }
})
